import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';

interface IDashboardDataResponse {
    checkin: number;
    checkout: number;
    inhouse:number
}

export const getDashboardData = async () => {
	return await axios
		.get<IDashboardDataResponse>(`/api/v2/admin/dashboard/orders`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error('Error getting dashboard data: ', error);
			return Promise.reject(error);
		});
};

type UseGetDashboardDataQueryOptions = UseQueryOptions<IDashboardDataResponse, any, IDashboardDataResponse>;

export function useGetDashboardDataQuery(
	options?: UseGetDashboardDataQueryOptions
) {
	return useQuery(
		['dashboard'],
		() => {
			return getDashboardData();
		},
		options as any
	);
}
