import React from 'react';



interface IAlertProps {
    type: 'success' | 'error'
    children: React.ReactNode
}

const Alert: React.FC<IAlertProps> = ({type='success', children})=>{
    const classNames = {
        'success': 'border border-[#6DD400] bg-[#F0FBE6]',
        'error': 'border border-[#D40000] bg-[##FBE6E6/50]'
    }
    return <div className={`rounded-lg px-5 py-3 font-bold text-gray-500 ${classNames[type]}`}>
        {children}
    </div>
}

export default Alert