import { Form, Formik, FormikHelpers } from 'formik';
import { Button, FieldInput, FieldTextArea } from 'litto-lib/components/common';
import React from 'react';
import * as Yup from 'yup';

import Alert from 'components/shared/Alert';
import { usePostListingRequestMutation } from 'services/listing-requests/postListingRequest';

interface IListingRequestFormData {
	message: string;
	airbnbUrl: string;
}
export interface IListingRequestFormProps {
	className?: string;
	onClose: () => void;
	onSuccess?: () => void;
}

export const NewListingRequestForm: React.FC<IListingRequestFormProps> = ({
	className,
	onClose
}) => {
	const { mutateAsync: postNewListingRequest, isSuccess } =
		usePostListingRequestMutation({
			onError: () => {},
			onSuccess: () => {}
		});

	const onLogIn = async (
		formData: IListingRequestFormData,
		formikHelpers: FormikHelpers<IListingRequestFormData>
	) => {
		const { setSubmitting } = formikHelpers;
		setSubmitting(true);

		try {
			await postNewListingRequest(formData);

			setSubmitting(false);
		} catch (err) {
			setSubmitting(false);
			return Promise.reject(err);
		}
	};
	if (isSuccess) {
		return (
			<div
				className={`${
					className || ''
				} bg-white flex flex-col gap-4 items-start pt-20`}
			>
				<Alert type={'success'}>
					You have successfully sent the listing request. After we review it, we
					will reach out.
				</Alert>
				<Button
					className="w-full md:w-auto ml-auto md:m-0"
					color="light"
					size="sm"
					onClick={onClose}
				>
					Close
				</Button>
			</div>
		);
	}
	return (
		<div className={`${className || ''} bg-white`}>
			<h1 className="text-[24px] leading-7 mt-10 mb-1">Add new listing</h1>
			<p className="text-sm text-gray-400 mb-4 leading-5">
				Help us to get best possible insight about your accommodation before we
				go online. Highlight the best of your place and paste us your Airbnb
				link.
			</p>
			<Formik
				initialValues={{
					message: '',
					airbnbUrl: ''
				}}
				validationSchema={Yup.object().shape({
					message: Yup.string().required('Field is required'),
					airbnbUrl: Yup.string().required('Field is required')
				})}
				onSubmit={onLogIn}
			>
				{props => {
					const { errors, submitForm, submitCount, isSubmitting } = props;
					return (
						<Form
							className="flex flex-col gap-4"
							noValidate={true}
							onKeyDown={e => {
								if (e.key === 'Enter') {
									submitForm();
								}
							}}
						>
							<FieldTextArea
								name="message"
								className="!w-full"
								placeholder="Tell us more"
								error={(submitCount && errors.message) || ''}
							/>
							<FieldInput
								name="airbnbUrl"
								label="Airbnb link"
								placeholder="Add link"
								error={(submitCount && errors.airbnbUrl) || ''}
							/>
							<div className="flex flex-col md:flex-row justify-end gap-4 items-center mt-4">
								<Button
									className="w-full md:w-auto ml-auto md:m-0"
									type="button"
									onClick={onClose}
									color="light"
									text="Close"
								></Button>
								<Button
									className="w-full md:w-auto"
									text="Send details"
									disabled={isSubmitting}
									loading={isSubmitting}
								/>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
