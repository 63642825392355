import { Button, Modal } from 'litto-lib/components/common';
import React from 'react';

import { NewListingRequestForm } from 'components/listings/listing-requests/NewListingRequestForm';
import { useGetAdminQuery } from 'services/admin/getAdmin';
import { useGetDashboardDataQuery } from 'services/dashboard/getDashboardData';

const Home: React.FC = () => {
	const { data: user } = useGetAdminQuery('me');
	const [listingModalVisible, setListingModalVisible] = React.useState(false);

	const { data: dashboardData } = useGetDashboardDataQuery();

	return (
		<>
			<div className="flex flex-col min-h-[calc(100vh-300px)]">
				<div className="my-auto pt-10 pb-24">
					<div className="container ">
						<h3 className="text-4xl">Hello {user?.firstName},</h3>
						<p className="mt-5">Here is your daily reservation update.</p>
						<div className="flex flex-col md:flex-row gap-4 mt-5">
							<div className="border rounded-lg p-10 min-w-[200px]">
								<p className="text-2xl font-bold">
									{dashboardData?.checkin || 0}
								</p>
								<p>Check-ins</p>
							</div>
							<div className="border rounded-lg p-10 min-w-[200px]">
								<p className="text-2xl font-bold">
									{dashboardData?.checkout || 0}
								</p>
								<p>Check-outs</p>
							</div>
							<div className="border rounded-lg p-10 min-w-[200px]">
								<p className="text-2xl font-bold">
									{dashboardData?.inhouse || 0}
								</p>
								<p>In house (active)</p>
							</div>
						</div>
						<Button
							onClick={() => setListingModalVisible(true)}
							className="mt-8"
						>
							Add new listing
						</Button>
					</div>
				</div>
			</div>
			<Modal
				className="!max-w-2xl "
				noHeader
				isOpen={listingModalVisible}
				onRequestClose={() => setListingModalVisible(false)}
			>
				<NewListingRequestForm
					onSuccess={() => setListingModalVisible(false)}
					onClose={() => setListingModalVisible(false)}
				/>
			</Modal>
		</>
	);
};

export default Home;
