import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from 'axios';

import { Entity } from 'services/interfaces';

export interface IListingRequestData {
	message: string;
	airbnbUrl: string;
}

export interface IListingRequestResponse extends Entity {
	status: string;
	message: string;
	airbnbUrl: string;
}

export const postListingRequest = async (
	listingRequestData: IListingRequestData
) => {
	return await axios
		.post<IListingRequestResponse>(
			`/api/v2/admin/new-listing-requests`,
			listingRequestData
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch((err: IListingRequestResponse) => {
			return Promise.reject(err);
		});
};

type useLoginMutationOptions = Omit<
	UseMutationOptions<
		IListingRequestResponse,
		unknown,
		IListingRequestData,
		unknown
	>,
	'mutationFn'
>;

export function usePostListingRequestMutation(
	options?: useLoginMutationOptions
) {
	return useMutation(postListingRequest, {
		...options,
		onError: e => {
			return Promise.reject(e);
		}
	});
}
